@import './variables';

.requested {
  color: $primary-color;
}
.react-datepicker-wrapper {
  width: 100%;
}

.nivel-container {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  color: #fff;
}

.nivel-container-1 {
  background-color: #f08080;
}

.nivel-container-2 {
  background-color: #adadad;
}

.nivel-container-3 {
  background-color: #ffcd09;
}
