@import './variables';

@mixin boxColor($color) {
  border-left-color: $color;
  .box-container {
    .box-title {
      color: $color;
    }
  }
  &:hover {
    i {
      color: $color;
    }
  }
}

.box {
  cursor: pointer;
  border: 1px solid #f1f1f1;
  box-shadow: 0 0.25rem 1rem rgba(48, 55, 66, .15);
  border-radius: .35rem;
  border-left: .22rem solid;
  padding: 15px 20px;
  overflow: hidden;
  background-color: #fff;
  height: 100%;
  transition: ease .2s;

  .box-content {
    min-height: 2rem;
    display: flex;
    align-items: center;
  }

  .box-container {
    flex: 1;

    .box-title {
      text-transform: uppercase;
      font-size: .6rem;
      font-weight: 700;
    }

    .box-body {
      line-height: 1rem;

      .box-value {
        font-size: 1.1rem;
        font-weight: 500;
        color: #5a5c69;
      }
    }
  }

  .box-icon {
    i {
      transition: linear .2s;
      font-size: 1.8rem;
      color: #bdc3c7;
    }
  }

  &:hover {
    margin-top: -5px;
    margin-bottom: 5px;
    
    .box-icon {
      i {
        font-size: 1.95rem;
      }
    }
  }

  &.box-primary {
    @include boxColor($primary-color);
  }

  &.box-secondary {
    @include boxColor(lighten($primary-color, 5%));
  }

  &.box-error {
    @include boxColor($error-color)
  }

  &.box-warning {
    @include boxColor($warning-color)
  }

  &.box-success {
    @include boxColor($success-color)
  }

  &.box-info {
    @include boxColor($info-color)
  }
}