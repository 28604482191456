@import './variables.scss';

@import '~spectre.css/src/spectre.scss';
@import '~spectre.css/src/spectre-icons.scss';
@import '~spectre.css/src/spectre-exp.scss';

.card {
  height: 100%;
  border: 0;
  box-shadow: 0 0.25rem 1rem rgba(48,55,66,.15);
}

.align-center {
  align-items: center;
}

.full-vh {
  min-height: 100vh;
}

.full-height {
  height: calc(100vh - 88px);
}

.btn.btn-info {
  border-color: $info-color;
  background-color: $info-color;
  color: #fff;
}

.btn:focus, .btn:hover {
  box-shadow: none;
}


.off-canvas .off-canvas-sidebar {
  z-index: 98;
}

.tooltip::after {
  z-index: 98;
}
